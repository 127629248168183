@import reset

/** variable **/

$animation-speed: 10s
$pc-width: 900px

@mixin flex-row
    display: flex
    flex-direction: row

@mixin flex-column
    display: flex
    flex-direction: column

@mixin flex-row-center-center
    @include flex-row
    align-items: center
    justify-content: center

@mixin flex-column-center-center
    @include flex-column
    align-items: center
    justify-content: center

@mixin pc
    @media (min-width: ($pc-width))
        @content

.pc
    display: none

    +pc
        display: inline

.mo
    +pc
        display: none

/** common style **/

.mouse
    background: yellow
    border-radius: 50%
    position: fixed
    z-index: 100
    pointer-events: none
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out

.mb-2
    margin-bottom: 1rem

.mt-2
    margin-top: 1rem

h3
    text-weight: bold

body
    overflow-x: hidden
    //font-family: 'メイリオ', Meiryo, '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif
    font-family: co-arabic, sans-serif

a
    text-decoration: none

.bg-yellow
    background-color: #FFE900

.top-container
    @include flex-column-center-center
    height: 100vh
    width: 100%

.text-container
    font-size: 12px
    font-weight: bold

    &.week
        font-weight: 400

    h3
        font-weight: bold

.sentence
    margin-bottom: 1rem

@mixin base-bg
    background:
        position: center
        repeat: no-repeat
        size: contain

@mixin blue-config
    backdrop-filter: blur(3px)
    -webkit-backdrop-filter: blur(3px)

#header
    position: absolute
    top: 0
    left: 0
    z-index: 1
    display: none

    .logo
        @include base-bg
        margin-top: 1rem
        padding-left: 5px
        height: 45px
        width: 155px
        background-image: url('../images/FRYTH.png')

#fixed-footer
    position: fixed
    bottom: 10px
    right: 10px
    z-index: 1000
    display: none

    .yellow-man
        @include base-bg
        @include flex-column-center-center
        min-height: 100px
        width: 100px
        background-image: url('../images/kiromaru.png')
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.3))

        @media screen and (min-width: $pc-width)
            min-height: 200px
            width: 200px

    a
        @include flex-row-center-center
        align-items: flex-end
        color: #707070
        color: white
        font-size: 16px
        line-height: 16px
        //-webkit-text-stroke: 0.5px #aaa
        //text-stroke: 0.5px #aaa
        font-weight: bold
        margin-bottom: 0.5rem

        @media screen and (min-width: $pc-width)
            font-size: 25px
            line-height: 30px

            .other
                line-height: 18px

        .top
            @media screen and (min-width: $pc-width)
                font-size: 200%

.footer-container
    .footer-contents-container
        position: relative
        overflow-x: hidden
        overflow-y: hidden
        height: 300px

        @media screen and (min-width: $pc-width)
            height: 600px

    .company-link
        @include flex-row-center-center
        background-color: #FFE900
        color: white
        position: absolute
        top: 60%
        bottom: 40%
        left: 0
        right: 0
        margin: auto
        border-radius: 20px
        font-size: 120%
        height: 50px
        width: 100px
        z-index: 10

        @media screen and (min-width: $pc-width)
            width: 150px
            height: 60px
            left: 20%
            margin: 0
            font-size: 150%

    .copyright
        position: absolute
        bottom: 10px
        left: 10px
        //backdrop-filter: blur(3px)
        //-webkit-backdrop-filter: blur(3px)
        z-index: 10
        font-size: 90%
        display: flex
        flex-direction: column

        @media screen and (min-width: $pc-width)
            flex-direction: row

        .copy
            margin-right: 0.5rem

        a
            color: black
            text-decoration: underline

    #footer-circle-title
        width: 150%
        font-size: 50px
        font-weight: bold
        display: none

        animation:
            name: rotate_clock
            duration: $animation-speed
            timing-function: linear
            iteration-count: infinite

        -webkit-animation:
            name: rotate_clock
            duration: $animation-speed
            timing-function: linear
            iteration-count: infinite

        @media screen and (min-width: $pc-width)
            font-size: 100px
            width: 160%
            display: block

    .footer-men
        @include flex-row-center-center
        position: absolute
        bottom: 10px
        right: 0
        justify-content: flex-end
        margin-right: 100px

        @media screen and (min-width: $pc-width)
            margin-right: 200px

        .man
            @include base-bg
            height: 30px
            width: 30px
            filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.3))

            @media screen and (min-width: $pc-width)
                height: 60px
                width: 60px

        .black-man
            background-image: url("../images/kuromaru.png")
            animation:
                name: bound-anim1
                duration: 4s
                iteration-count: infinite
        .red-man
            background-image: url("../images/akamaru.png")
            animation:
                name: bound-anim2
                duration: 7s
                delay: 1s
                iteration-count: infinite

        .green-man
            background-image: url("../images/midorimaru.png")
            animation:
                name: bound-anim2
                duration: 7s
                delay: 3s
                iteration-count: infinite

        .blue-man
            background-image: url("../images/aomaru.png")
            animation:
                name: bound-anim1
                duration: 4s
                delay: 1s
                iteration-count: infinite

@keyframes rotate_clock
    0%
        transform: rotate(0)
    100%
        transform: rotate(-360deg)

@-webkit-keyframes rotate_clock
    0%
    transform: rotate(0)
    100%
    transform: rotate(-360deg)

@keyframes bound-anim1
    0%, 100%
        top: 0
        transform: scale(1)

    10%
        transform: translateY(-20px)

    20%
        transform: translateY(10px) scale(1.1, 0.9)

    30%
        transform: scale(1)

@keyframes bound-anim2
    0%, 100%
        top: 0
        transform: scale(1)

    50%
        transform: scale(1)

    55%
        transform: translateY(-30px)

    60%
        transform: translateY(10px) scale(1.1, 0.9)

    65%
        transform: scale(1)

/* index */

#load-mv-pc, #load-mv-mo
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    height: 100vh
    width: 101vw
    z-index: 10000
    text-align: center
    object-fit: fill
    //mix-blend-mode: screen

    video
        height: 100vh
        width: 100vw

        /** 
         -moz-transform:scale(1.5)
         -webkit-transform:scale(1.5)
         -o-transform:scale(1.5)
         -ms-transform:scale(1.5)
         transform:scale(1.5)

        //@media screen and (min-width: $pc-width)
        height: 100%
        width: 100%
        -moz-transform: scale(1)
        -webkit-transform: scale(1)
        -o-transform: scale(1)
        -ms-transform: scale(1)
        transform: scale(1)

#index
    position: relative
    width: 100%
    height: calc(100vh - 100px )

    @media screen and (min-width: $pc-width)
        height: 100vh

    canvas#fryth-logo
        width: 100%
        height: 100%
        display: none

    .home-button-container
        @include flex-column-center-center
        height: 150px
        width: 150px
        position: absolute
        top: 0
        bottom: -300px
        left: 0
        right: 0
        margin: auto

        background:
            image: url('../images/kiromaru.png')
            size: contain
            position: center
            repeat: no-repeat
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.3))

        @media screen and (min-width: $pc-width)
            align-items: center

        //.to-home
            @include flex-column-center-center
            height: 50px
            width: 150px
            background-color: yellow
            text-align: center
            margin-left: 1rem
            border-radius: 30px
            box-shadow: 0 3px 6px #00000069
            color: #858585

        .text
            color: #707070
            font-size: 16px
            line-height: 16px
            border-bottom: 1px solid #707070
            margin-bottom: 0.5rem

#canvas-wrap, #canvas-container
    height: 100vh
    width: 100vw
    position: fixed
    z-index: -1

/** home **/

#load-bg
    background: #FFF
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 9000

#load-mv-mo, #load-mv-pc
    dispaly: none

#main-contents
    .top-container
        font-size: 104px

    /*.canvas-filter
     position: fixed
     height: 100%
     width: 100%
     //background-color: rgba(255,255,255,0.3)
     //filter: blur(4px)
     //-webkit-filter: blur(4px)
     //-ms-filter: blur(4px)
     z-index: 0

    .container-title, .container-title-mirror
        font-size: 104px
        font-weight: bold
        //font-family: 'メイリオ', 'Meiryo', sans-serif
        overflow-y: hidden

        @media screen and (min-width: $pc-width)
            font-size: 300px

    .container-title-mirror
        position: relative
        z-index: -1
        color: white
        -webkit-text-stroke: 2px black

    .subtitle
        font-size: 28px
        font-weight: bold
        //font-family: 'メイリオ', 'Meiryo', sans-serif
        //margin-top: -100px
        padding-top: 50px

        @media screen and (min-width: $pc-width)
            font-size: 60px
            //margin-top: -300px

    .about-container
        //padding-bottom: 50px
        position: relative

        .container-title-container
            overflow-x: hidden
            position: absolute
            width: 100%
            top: -50vh
            display: none

            @media screen and (min-width: $pc-width)
                top: -100vh

        .container-title
            animation: flowing-left $animation-speed linear infinite
            transform: translateX(100%)
            width: 150%

        .container-title-mirror
            animation: flowing-right $animation-speed linear infinite
            transform: translateX(-100%)
            width: 150%

        .sub-title-container
            //margin-top: 200px

        .sub-title-container, .text-container
            padding: 0 1rem

            @media screen and (min-width: $pc-width)
                padding: 0 10rem

        .text-container
            margin-top: 80px

            @media screen and (min-width: $pc-width)
                font-size: 20px

    .business-container
        @include flex-column-center-center
        align-items: flex-end
        overflow: hidden

        @media screen and (min-width: $pc-width)
            min-height: 100vh

        .container-title-container
            padding: 0 1rem
            @include flex-row
            width: 100%
            //height: 400px
            align-items: flex-start

        .container-title, .container-title-mirror
            height: 200px
            width: 500px
            transform: rotate(90deg)
            overflow: visible

        .container-title
            animation: flowing-bottom $animation-speed linear infinite
            transform: rotate(90deg) translateX(-100%)
            z-index: -1
            margin-left: -250px

            @media screen and (min-width: $pc-width)
                transform: rotate(90deg) translateX(-300%)
                margin-left: 0

        .container-title-mirror
            animation: flowing-up $animation-speed linear infinite
            transform: rotate(90deg) translateX(150%)
            margin-left: -350px

            @media screen and (min-width: $pc-width)
                animation: flowing-up-pc $animation-speed linear infinite
                transform: rotate(90deg) translateX(200%)
                margin-left: -200px

        .sub-title-container, .text-container
            padding: 0 1rem

        .subtitle
            width: 270px

            @media screen and (min-width: $pc-width)
                width: 600px

        .text-container
            margin-bottom: 100px
            margin-top: 50px

            @media screen and (min-width: $pc-width)
                font-size: 20px

    .more-read-container
        @include flex-column-center-center

        background-color: rgb(255, 233, 0)
        height: 100px
        width: 100%
        text-align: center
        position: relative
        color: rgba(100,100,100,1)
        //background-color: rgba(0,0,0,0)
        text-align: center
        text-decoration: none
        transition: .3s
        overflow: hidden
        z-index: 1
        dislpay: block

        @media screen and (min-width: $pc-width)
            align-items: center

        .logo
            @include base-bg
            height: 60%
            width: 200px
            background-image: url('../images/FRYTH.png')
            margin-left: 1rem

        .text
            height: 25px

        //&::before, &::after
            content: ""
            width: 130%
            height: 100%
            position: absolute
            top: 0
            z-index: -1
            transition: transform ease .3s

        //&::before
            right: 0
            border-right: 200px solid transparent
            //border-bottom: 200px solid rgba(20,20,20,1)
            border-bottom: 200px solid white
            transform: translateX(-100%)

        //&::after
            left: 0
            border-left: 200px solid transparent
            //border-top: 200px solid rgba(20,20,20,1)
            border-top: 200px solid white
            transform: translateX(100%)

        &:hover
            color: #fff

        &:hover::before
            transform: translateX(20%)

        &:hover::after
            transform: translateX(-20%)

        .more-read-btn
            @include flex-column-center-center
            height: 90px
            width: 300px
            text-align: center
            z-index: 2

            .btn-text
                font-size: 40px
                color: white

@keyframes flowing-left
    100%
        transform: translateX(-100%)

@keyframes flowing-left-company
    100%
        transform: translateX(-150%)

@keyframes flowing-right
    100%
        transform: translateX(100%)

@keyframes flowing-bottom
    100%
        transform: rotate(90deg) translateX(200%)

@keyframes flowing-up
    100%
        transform: rotate(90deg) translateX(-100%)

@keyframes flowing-up-pc
    100%
        transform: rotate(90deg) translateX(-400%)

@keyframes dropVanish
    30%
        transform: translate(0, -50px) rotate(180deg) scale(1)

    50%
        transform: translate(0, 20px) scale(.8) rotate(0deg)
        opacity: 1

    80%
        transform: translate(-100px, -100px) scale(1.5) rotate(-180deg)
        opacity: 0

    100%
        transform: translate(0) scale(1) rotate(0deg)
        opacity: 1

@keyframes revolveDrop
    30%
        transform: translate(0, -50px) rotate(180deg) scale(1)

    60%
        transform: translate(0, 20px) scale(.8) rotate(0deg)

    100%
        transform: translate(0) scale(1) rotate(0deg)
        opacity: 1

.main-content-container
    @include blue-config
    //visibility: hidden

#more-read-contents
    //@include blue-config
    width: 100%

    .top-container
        overflow-x: hidden

    .container-title.business, .container-title-mirror.business
        font-size: 104px
        font-weight: bold
        //font-family: 'メイリオ', 'Meiryo', sans-serif
        z-index: -1

    .container-title-mirror
        position: relative
        color: white
        -webkit-text-stroke: 2px black

    .business-container
        @include flex-column-center-center

        width: 100%
        overflow: hidden

        .container-title-container
            @include flex-column-center-center
            padding: 0 1rem
            width: 100%
            height: 100vh
            align-items: flex-start
            overflow: hidden

        .container-title, .container-title-mirror
            transform: rotate(90deg)
            overflow: visible
            font-size: 104px
            font-weight: bold
            //font-family: 'メイリオ', 'Meiryo', sans-serif
            overflow-y: hidden

            @media screen and (min-width: $pc-width)
                font-size: 280px

        .container-title
            animation: flowing-left $animation-speed linear infinite
            -webkit-animation: flowing-left $animation-speed linear infinite
            transform: translateX(100%)

        .container-title-mirror
            animation: flowing-right $animation-speed linear infinite
            -webkit-animation: flowing-right $animation-speed linear infinite
            transform: translateX(-100%)
            margin-top: -50px

    .content-container
        padding: 0 1rem
        padding-top: 2rem
        padding-bottom: 100px

        position: relative

        @media screen and (min-width: $pc-width)
            padding: 0 3rem
            padding-bottom: 300px

    $strong-char-size: 90px
    $pc-strong-char-size: 200px

    .strong-char-container
        position: absolute
        width: $strong-char-size
        height: $strong-char-size
        z-index: -1
        margin-top: -1.5rem
        margin-left: -1.5rem
        font-size: 58px
        font-weight: bold
        //font-family: 'メイリオ', 'Meiryo', sans-serif
        overflow: hidden

        @media screen and (min-width: $pc-width)
            width: $pc-strong-char-size
            height: $pc-strong-char-size
            font-size: 150px
            margin-top: -5rem
            margin-left: -3rem

    .strong-char-square
        position: absolute
        width: $strong-char-size
        height: $strong-char-size

        @media screen and (min-width: $pc-width)
            width: $pc-strong-char-size
            height: $pc-strong-char-size

        &.communication
            background-color: yellow

        &.development
            background-color: #51A427

        &.design
            background-color: #003D90

    .slide-right
        animation:
            name: slide-right
            duration: 2s
            delay: 0.5s
            iteration-count: 1
            fill-mode: forwards

        @media screen and (min-width: $pc-width)
            animation-duration: 1s

    .slide-up
        animation:
            name: slide-up
            duration: 1s
            delay: 0.5s
            iteration-count: 1
            fill-mode: forwards

    .container-title-strong-char
        @include flex-column-center-center

        height: $strong-char-size
        width: $strong-char-size
        transform: translateY(100px)

        @media screen and (min-width: $pc-width)
            width: $pc-strong-char-size
            height: $pc-strong-char-size

        &.communication
            color: yellow

        &.development
            color: #51A427

        &.design
            color: #003D90

    .container-title
        font-size: 38px
        font-weight: bold
        //font-family: 'メイリオ', 'Meiryo', sans-serif

        @media screen and (min-width: $pc-width)
            font-size: 70px

    .text-container
        @media screen and (min-width: $pc-width)
            margin-top: 5rem
            font-size: 20px

.main-content-container.company
    .top-container
        height: 175px

        @media screen and (min-width: $pc-width)
            height: 250px

        .container-title-container
            justify-content: flex-end !important

#more-read-contents.company
    .main-content-container
        backdrop-filter: none
        -webkit-backdrop-filter: none

    .content-container
        padding-top: 0

        @media screen and (min-width: $pc-width)
            padding-top: 2rem

    .container-title
        font-size: 60px
        transform: translateX(300%)
        animation: flowing-left-company $animation-speed linear infinite

        @media screen and (min-width: $pc-width)
            font-size: 120px

    .text-container
        @include flex-column-center-center
        margin-top: 2rem

        .table
            width: 500px
            max-width: 90vw
            backdrop-filter: blur(3px)
            -webkit-backdrop-filter: blur(3px)
            padding: 2rem
            border: 1px solid gray
            border-radius: 20px

            &.wide
                width: 750px

@keyframes slide-right
    100%
        transform: translateX(200px)

@-webkit-keyframes slide-right
    100%
    transform: translateX(200px)

@keyframes slide-up
    100%
        transform: translateY(0px)

@-webkit-keyframes slide-up
    100%
    transform: translateY(0px)
